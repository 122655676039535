import { ChatResponse, ChatRequest } from "./models";
import { isWebLink } from "../utils/citations";
import { msalInstance, acquireBearerToken } from "./auth";

const appVersion = import.meta.env.VITE_APP_VERSION
const apiVersion = import.meta.env.VITE_API_VERSION || "v3";

let isAPIDisabled = false;

export async function disableAPI(): Promise<void> {
    isAPIDisabled = true;
}

export async function getCurrentVersion(): Promise<string> {
    const bearerToken = await acquireBearerToken(msalInstance);
    const response = await fetch(`/api/${apiVersion}/version`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: bearerToken
        },
    });
    const { appVersion } = await response.json();
    return appVersion;
}

function extractBotText(bot: any): string {
    // the goal is to extract all string values from the bot object and concatenate them into a single string, separating them with newlines.
    let botText = "";

    if (typeof bot === "string") {
        return bot;
    } else if (typeof bot === "object" && bot !== null) {
        for (const key in bot) {
            if (typeof bot[key] === "string") {
                botText += bot[key] + "\n";
            } else if (Array.isArray(bot[key])) {
                bot[key].forEach((item: any) => {
                    botText += extractBotText(item) + "\n";
                });
            } else if (typeof bot[key] === "object") {
                botText += extractBotText(bot[key]) + "\n";
            }
        }
    }

    return botText.trim();
}

export async function chatApi(options: ChatRequest): Promise<ChatResponse> {
    if (isAPIDisabled) {
        throw new Error("API is disabled");
    }
    const bearerToken = await acquireBearerToken(msalInstance);
    try {
        let overrides: any = {
            semantic_ranker: options.overrides?.semanticRanker,
            top: options.overrides?.top,
            temperature: options.overrides?.temperature,
            prompt_template: options.overrides?.promptTemplate,
            suggest_followup_questions: options.overrides?.suggestFollowupQuestions,
            gpt_version: options.overrides?.gptVersion,
            native_search: options.overrides?.nativeSearch,
            agent_type: options.overrides?.agentType,
            llm_model_name: options.overrides?.llmModelName,
            profile: options.overrides?.profile,
            tools: options.overrides?.tools,
            showPreviewFeatures: options.overrides?.showPreviewFeatures,
            appVersion: appVersion,
        };

        if (options.version === "v1") {
            overrides.filter_category = options.overrides?.filterCategory;
        } else if (options.version === "v3") {
            overrides.use_files = options.overrides?.use_files;
        }

        const transformedHistory = options.history.map(entry => ({
            user: entry.user,
            bot: extractBotText(entry.bot)
        }));

        let queryBody: any = {
            history: transformedHistory,
            overrides: overrides
        };

        if (options.version === "v2") {
            queryBody.approach = options.approach;                        
        } else{
            queryBody.conversation_id = options.conversation_id;
        }

        const formData = new FormData();
        formData.append("query", JSON.stringify(queryBody));
        // Append files to formData
        if (options.version === "v2" && options.files) {
            options.files.forEach((file, index) => {
                formData.append(
                    `file${index}`,
                    file,
                    file.name
                );
            });
        }
        // Content-Type is automatically set by the browser to multipart/form-data
        // since both JSON body and file content are present
        const response = await fetch(`/api/${options.version}/chat`, {
            method: "POST",
            headers: {
                Authorization: bearerToken
            },
            body: formData
        });

        const parsedResponse: ChatResponse = await response.json();
        if (response.status > 299 || !response.ok) {
            console.log("Error Response from API: " + parsedResponse.error);
            throw Error("Ummm, this is embarassing! I seem to have lost my train of thought while trying to figure this out. Do you want me to try again?");
        }
        return parsedResponse;
    } catch (error: any) {
        console.log("Error Response from API: " + error?.message);
        throw Error("Ummm, this is embarassing! I seem to have lost my train of thought while trying to figure this out. Do you want me to try again?");
    }
}

export async function sendFeedback(feedback: object): Promise<void> {
    if (isAPIDisabled) {
        throw new Error("API is disabled");
    }
    try {
        const bearerToken = await acquireBearerToken(msalInstance);
        await fetch(`/api/${apiVersion}/submit_feedback`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: bearerToken
            },
            body: JSON.stringify(feedback)
        });
    } catch (error) {
        throw Error("Error sending feedback data. Error Response from API: " + error);
    }
}

export async function getFeedbackData(format: string): Promise<any[]> {
    if (isAPIDisabled) {
        throw new Error("API is disabled");
    }
    try {
        const bearerToken = await acquireBearerToken(msalInstance);
        const response = await fetch(`/api/${apiVersion}/get_feedback_data`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: bearerToken
            },
            body: JSON.stringify(format)
        });

        if (!response.ok) {
            const text = await response.text();
            throw new Error(`Error fetching feedback data. Server responded with status ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        throw new Error(`Error fetching feedback data. Error Response from API: ${error}`);
    }
}

export async function deleteConversationIndex(conversationId: string): Promise<any> {
    if (isAPIDisabled) {
        throw new Error("API is disabled");
    }
    const bearerToken = await acquireBearerToken(msalInstance);
    try {
        const queryBody = {
            conversation_id: conversationId
        };

        const formData = new FormData();
        formData.append("query", JSON.stringify(queryBody));

        const response = await fetch(`/api/${apiVersion}/conv_delete`, {
            method: "POST",
            headers: {
                Authorization: bearerToken
            },
            body: formData
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || "Failed to delete conversation index");
        }

        const data = await response.json();
        return data;
    } catch (error: any) {
        console.error("Error deleting conversation index:", error);
        throw error;
    }
}

export function getCitationFilePath(citation: string): string {
    // if a web protocol found then return the path in the parameter citation as is.
    // if no web protocol found then it is a file in Azure storage hence prefix the container name to it to derive relative path
    // TODO, container name = content, should be dynamic because it is an environment variable in backend
    // however react only supports build time variables
    return isWebLink(citation) ? citation : `/content/${citation}`;
}

export async function uploadFiles(files: File[], conversationId: string, deletedFiles: string[] = []): Promise<any> {
    if (isAPIDisabled) {
        throw new Error("API is disabled");
    }
    const bearerToken = await acquireBearerToken(msalInstance);
    try {
        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append(`file${index}`, file);
        });
        formData.append("query", JSON.stringify({
            conversation_id: conversationId,
            overrides: {},
            deleted_files: deletedFiles
        }));

        const response = await fetch(`/api/${apiVersion}/upload`, {
            method: "POST",
            headers: {
                Authorization: bearerToken
            },
            body: formData
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || "Upload failed");
        }

        const data = await response.json();
        return data;
    } catch (error: any) {
        console.error("Error uploading files:", error);
        throw error;
    }
}